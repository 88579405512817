import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'

class QRRedirect extends React.Component {
  componentDidMount() {
    navigate('/')
    // if (isIOS) navigate('/iOS')
    // else if (isAndroid) navigate('/android')
    // else navigate('/')
  }

  render() {
    return <Layout />
  }
}

export default QRRedirect
